.game-title{
    margin-top: 1%;
    width: 100%;
    padding-left: 2%;
    background-color: var(--fade-black);
    font-size: calc(1.325rem + 0.9vw);
    color: white;
    vertical-align:middle;
}

.primary-iframe{
    margin-top: 2vh;
    max-width: 1280px;
    max-height: 960px;
    position: relative;
    width: 100%;
    height: 80vh;
    top: 10%;
    background-color: var(--fade-black);
}