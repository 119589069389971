.game-link {
    width: 100%;
    height: 150px;
    background-color: var(--flex-pink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    color: #fff;
    margin-bottom: 5%;
}

.game-link > span {
    font-size: large;
    font-weight: 600;
}